import { createLocalVue, shallowMount } from '@vue/test-utils';
import Vuelidate from 'vuelidate';
import Vuex from 'vuex';
import flushPromises from 'flush-promises';
import ToastHelper from '@/shared/helpers/toast';
import { ActivityService } from '@/sparkmembers/services/activity';
import lessonService from '@/sparkmembers/services/lesson';
import courseService from '@/sparkmembers/services/course';
import LessonEditor from './index.vue';

// NOTE: anything that requires
// AnalyticsService is extremely coupled
// to store and router.
jest.mock('@/router');
jest.mock('@/store');
jest.mock('@/shared/helpers/toast');
jest.mock('@/sparkmembers/services/lesson');
jest.mock('@/sparkmembers/services/course');
jest.mock('@/sparkmembers/services/media');
jest.mock('@/sparkmembers/services/activity');
jest.mock('@/services/analytics');

function mountWrapper(localVue, stubs, store) {
  return shallowMount(LessonEditor, {
    localVue,
    stubs,
    data() {
      return {
        content: { available: false },
        lesson: { id: 10, schedule: {}, title: 'teste' },
        activity: null,
        media: null,
        medias: [],
        commentsActivated: true,
        isLoading: true,
        isUpdating: false,
        isAddingAdditionalMedia: false,
        nextContentId: null,
        premiumFeatureLocation: null,
      };
    },
    mocks: {
      $t: jest.fn().mockImplementation(x => x),
      $router: {
        push: jest.fn(),
      },
      $route: {
        params: {
          contentId: 10,
        },
      },
      $store: new Vuex.Store(store),
    },
  });
}

const localVue = createLocalVue();
localVue.directive('b-modal', {});
localVue.use(Vuelidate);
localVue.use(Vuex);

describe('Lesson', () => {
  let wrapper;
  const stubs = {
      HsGroup: true,
      HsForm: true,
      HsIcon: true,
      HsInput: true,
      HsFormInvalidFeedback: true,
      HsButton: {
        template: '<div class="hs-button-stub"></div>',
        props: ['disabled'],
      },
      LessonReleaseLimitationCard: true,
      Editor: true,
    },
    store = {
      modules: {
        school: {
          namespaced: true,
          state: {
            selectedSchool: {
              id: 123,
              email: 'teste@herospark.com',
            },
          },
          getters: {
            getDomain: () => [],
          },
        },
        auth: {
          namespaced: true,
          state: {
            loggedUser: { id: 1, title: 'My Title', niche: null, subtitle: null },
          },
          actions: {
            updateProduct: jest.fn(),
          },
        },
        product: {
          namespaced: true,
          state: {
            selectedProduct: { id: 1, title: 'My Title', niche: null, subtitle: null },
          },
          actions: {
            updateProduct: jest.fn(),
          },
        },
        course: {
          namespaced: true,
          state: {
            selectedCourse: { id: 1, name: 'My Title' },
          },
          actions: {
            updateCourse: jest.fn(),
          },
        },
        loadAutomationsFromSelectedLesson: jest.fn(),
      },
    },
    hideStub = jest.fn();

  beforeEach(() => {
    wrapper = mountWrapper(localVue, stubs, store, hideStub);
    wrapper.setData({
      isLoading: false,
      commentsActivated: true,
      activity: {
        exam_questions: [
          {
            point: 2,
            correct: 1,
            id: 1,
            title: 'My Title',
            type: 'MultipleChoiceExamQuestion',
            multiple_choice_options: [
              { correct: false, id: 1, title: 'Answer title' },
              { correct: true, id: 2, title: 'Answer title 2' },
            ],
          },
        ],
        id: 1,
        exam_lesson: { id: 0, min_grade: 1 },
      },
    });
  });

  describe('behaviour', () => {
    describe('on mounted', () => {
      it('should have automation component', () => {
        expect(wrapper.find('#automations').element).toBeTruthy();
      });
    });
    describe('when create activity', () => {
      beforeEach(() => {
        jest.clearAllMocks();
      });

      it('should update lesson', async () => {
        expect.assertions(2);
        expect(lessonService.update).not.toHaveBeenCalled();

        wrapper.find('#lesson-main-content').vm.$emit('activity');

        await flushPromises();

        expect(lessonService.update).toHaveBeenCalledWith({ id: 10, min_grade: 0, type: 'ExamLesson' });
      });
      it('should create activity', async () => {
        expect.assertions(1);
        wrapper.find('#lesson-main-content').vm.$emit('activity');

        await flushPromises();

        expect(ActivityService.create).toHaveBeenCalledWith({
          exam_lesson_id: 10,
          exam_questions_attributes: {},
          max_attempts: 1,
          retry_time: 0,
          time_limit: 0,
          title: 'sparkmembers.contents.views.editor.components.lesson.title',
          titleHasError: false,
          type: 'Quiz',
        });
      });
    });
    describe('when edit lesson', () => {
      beforeEach(() => {
        jest.clearAllMocks();
      });
      describe('and call services', () => {
        beforeEach(() => {
          jest
            .spyOn(lessonService, 'addCommentsInLesson')
            .mockImplementation()
            .mockResolvedValue({ forum: [], section: [] });
        });
        it('should add comments in lesson', async () => {
          expect.assertions(2);
          expect(lessonService.addCommentsInLesson).not.toHaveBeenCalled();

          wrapper.find('#on-details-saved').vm.$emit('click');

          await flushPromises();

          expect(lessonService.addCommentsInLesson).toHaveBeenCalledWith({
            description: undefined,
            lesson_id: 0,
            school_product_id: 1,
            title: 'teste',
          });
        });

        it('should call update lesson service', async () => {
          expect.assertions(2);
          expect(lessonService.update).not.toHaveBeenCalled();

          wrapper.find('#on-details-saved').vm.$emit('click');

          await flushPromises();

          expect(lessonService.update).toHaveBeenCalledWith({
            id: 0,
            min_grade: 1,
            schedule: {},
            section_id: undefined,
            title: 'teste',
          });
        });

        it('should call update course service', async () => {
          expect.assertions(2);
          expect(courseService.updateCourseContent).not.toHaveBeenCalled();

          wrapper.find('#on-details-saved').vm.$emit('click');

          await flushPromises();

          expect(courseService.updateCourseContent).toHaveBeenCalledWith({
            available: false,
          });
        });
      });
      describe('and return error', () => {
        beforeEach(() => {
          jest
            .spyOn(lessonService, 'addCommentsInLesson')
            .mockImplementation()
            .mockRejectedValue([]);
        });
        it('should call toast danger', async () => {
          expect.assertions(1);
          wrapper.find('#on-details-saved').vm.$emit('click');
          await flushPromises();

          expect(ToastHelper.dangerMessage).toHaveBeenCalledTimes(1);
        });
      });

      describe('and return success', () => {
        beforeEach(() => {
          jest.clearAllMocks();
          wrapper.setData({
            commentsActivated: false,
          });
          jest
            .spyOn(lessonService, 'addCommentsInLesson')
            .mockImplementation()
            .mockResolvedValue([]);
          jest
            .spyOn(lessonService, 'update')
            .mockImplementation()
            .mockResolvedValue([]);
          jest
            .spyOn(courseService, 'updateCourseContent')
            .mockImplementation()
            .mockResolvedValue([]);
        });
        it('should call toast success', async () => {
          expect.assertions(1);
          wrapper.find('#on-details-saved').vm.$emit('click');
          await flushPromises();

          expect(ToastHelper.successMessage).toHaveBeenCalledTimes(1);
        });
      });
    });
  });
});
